@import url('https://fonts.googleapis.com/css2?family=Neonderthaw&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300&family=Libre+Baskerville:ital@1&family=Rubik+Beastly&display=swap');
@media only screen and (max-width: 600px){ /* Mobile */
	* { 
		margin:0;
		padding:0;
		box-sizing: border-box;
	} 
	
	.home{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		height: 80vh;
		font-family: 'Kalam', cursive;
	}
	.job{
		font-size: 2em;
		color: navy;
		font-weight: bolder;
		text-shadow: 0px 2px 4px #F8F8F8;
	}
	.picture{
		height: 400px;
		width: 200px;
		border-radius: 1%;
		border-radius: 2%;
		border: 3px solid navy;
	}
	
}
@media only screen and (min-width: 601px){ /* Windows/Mac */
	* { 
		margin:0;
		padding:0;
		box-sizing: border-box;
	} 
	
	.home{
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 80vh;
		font-family: 'Kalam', cursive;
	}
	.job{
		font-size: 2em;
		color: #F8F8F8;
		font-weight: bolder;
		text-shadow: 0px 2px 4px black;
		font-size: 4em;
	}
	.picture{
		height: 500px;
		width: 300px;
		border-radius: 2%;
		border: 3px solid #F8F8F8;
	}
	
}
