@import url('https://fonts.googleapis.com/css2?family=Neonderthaw&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&family=Rubik+Beastly&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300&family=Libre+Baskerville:ital@1&family=Rubik+Beastly&display=swap');
@media only screen and (max-width: 600px){ /* Mobile */
    * { 
        margin:0;
        padding:0;
        box-sizing: border-box;
    } 
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left:10px;
        padding-right: 10px;
        height: 10vh;
        color: antiquewhite;
        font-family: 'Neonderthaw', cursive;
    }
    .name{
        display: inline-flex;
        font-size: 2em;
    }
    .navSection{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 1em;
    }
    .navBtn {
        display: inline;
    }
    #link{
        font-family: 'Libre Baskerville', serif;
        border-bottom: 1px solid white;
    }

    #menuBtn{
        display: flex;
        justify-content: baseline;
        background: none;
        color: antiquewhite;
        font-size: .70em;
        font-family: 'Libre Baskerville', serif;
        height: 3.15em;
        width: auto;    
    }
    ul{
        background-color: antiquewhite;
    }
}

@media only screen and (min-width: 601px){ /* Windows/Mac */
    * { 
        margin:0;
        padding:0;
        box-sizing: border-box;
    } 
    .header{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding-left:10px;
        padding-right: 10px;
        height: 10vh;
        color: antiquewhite;
        font-family: 'Kalam', cursive;
    }
    .name{
        display: inline-flex;
        font-size: 4em;
        color: navy;
		font-weight: bolder;
		text-shadow: 0px 2px 4px #F8F8F8;
		-webkit-text-stroke: .1px #F8F8F8;
    }
    .navSection{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 2em;
    }
    .navBtn {
        display: inline;
    }
    #link{
        font-family: 'Libre Baskerville', serif;
        border-bottom: 1px solid white;
    }

    #menuBtn{
        display: flex;
        background: none;
        color: antiquewhite;
        height: 4em;
        font-size: 30px;
        color: navy;
		font-weight: bolder;
		text-shadow: 0px 2px 4px #F8F8F8;
		-webkit-text-stroke: .1px #F8F8F8;
        font-family: 'Kalam', cursive;
    }
    ul{
        background-color: antiquewhite;
    
    }
}
