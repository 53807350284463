@media only screen and (max-width: 600px){ /* Mobile */
	
	* { 
		margin:0;
		padding:0;
		box-sizing: border-box;
	} 
	#app{
		height: 100vh;
		width: 100vw;
	}
	.app{
		display: grid;
		grid-template-rows: repeat(3, 1fr);
		background-image: url(https://res.klook.com/images/fl_lossy.progressive,q_65/c_fill,w_1295,h_859/w_80,x_15,y_15,g_south_west,l_Klook_water_br_trans_yhcmh3/activities/w47ijjxy5so4qaxmfi6y/KeramaTokashikiIslandSwimming,Snorkeling,andTurtle-FindingExperienceinOkinawa.webp);
		background-repeat: no-repeat;
		background-attachment: fixed;
  		background-position: center;
		background-size: cover;
		height: 100vh;
		width: 100vw;
	}
}

@media only screen and (min-width: 601px){ /* Windows/Mac */
	* { 
		margin:0;
		padding:0;
		box-sizing: border-box;
	} 
	.app{
		display: grid;
		grid-template-rows: repeat(3, 1fr);
		background-image: url("https://gaijinpot.scdn3.secure.raxcdn.com/app/uploads/sites/6/2017/12/american-village-1.jpg");
		background-repeat: no-repeat;
		background-attachment: fixed;
  		background-position: center;
		background-size: cover;
		height: 100vh;
		width: 100vw;
	}
}