@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&family=Rubik+Beastly&display=swap');

@media only screen and (max-width: 600px){ /* Mobile */
	* { 
		margin:0;
		padding:0;
		box-sizing: border-box;
	}
	.about{
		display: flex;
		flex-direction: column;
		height: 80vh;
		background: none;
		font-family: 'Libre Baskerville', serif;
		color: #F8F8F8;
		font-weight: bolder;
		font-size: x-large;
		justify-content: space-around;
	}

	.title{
		justify-self: center;
		align-self: center;
		padding: 2rem;
	}
	.aboutMe{
		display: flex;
		flex-direction: column;
		overflow: scroll;
		width: 100%;
		height: 100%;
	}
	.familyPics{
		height: 30vh;
		justify-content: space-around;
		align-content: space-around;
	}
	.familyPics img{
	max-width: 100%;
  	max-height: 100%;
  	margin: auto;
  	display: block;
	}
	p.info{
		justify-self: center;
	}
	
  .facts {
	overflow: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	background-color: rgba(39, 10, 205, 0.5);
	
  }
  .facts::-webkit-scrollbar {
	display: none;
  }
}
.emailLink{
	font-family: 'Libre Baskerville', serif;
	color: #F8F8F8;
	font-weight: bolder;
	font-size: x-large;
}

@media only screen and (min-width: 601px){ /* Windows/Mac */
	* { 
		margin:0;
		padding:0;
		box-sizing: border-box;
	}
	.about{
		display: flex;
		flex-direction: row;
		height: 80vh;
		background: none;
		padding: 10px;
		font-family: 'Libre Baskerville', serif;
		color: #F8F8F8;
		font-weight: bolder;
		text-shadow: 1px 2px 4px black;
		font-size: 2em;
		justify-content: space-around;
	}
	.title{
		justify-self: center;
		align-self: center;
		padding: 2rem;
	}
	.aboutMe{
		display: flex;
		flex-direction: column;
		width: 49vw;
		padding-right: 10px;
	}
	.familyPics{
		display: flex;
		justify-content: space-around;
		justify-content: center;
	}
	.pictures{
		display: flex;
		width: 40vw;
		height: 70vh;
		margin-top: 75px;
	}
	p.info{
		justify-self: center;
	}
  .facts {
	overflow: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	background-color: rgba(0, 0, 0, 0.611);
  }
  .facts::-webkit-scrollbar {
	display: none;
  }
}

