@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&family=Rubik+Beastly&display=swap');

@media only screen and (max-width: 600px){ /* Mobile */
	* { 
		margin:0;
		padding:0;
		box-sizing: border-box;
	}
	.projects{
		display: flex;
		flex-direction: column;
		height: 80vh;
		font-family: 'Libre Baskerville', serif;
		color: #F8F8F8;
		font-weight: bolder;
		font-size: large;
		width: 100%;
		overflow: scroll;
	}
	.projects::-webkit-scrollbar {
		display: none;
	}
	.projectList{
		display: flex;
		height: 90vh;
		flex-direction: column;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	.projectList::-webkit-scrollbar {
		display: none;
	}
	.project{
		display: flex;
		flex-direction: column;
		border: 1px solid #F8F8F8;
		padding-top: 10px;
		padding-bottom: 10px;
		overflow: scroll;
		-ms-overflow-style: none;
		scrollbar-width: none;
		width: 100%;
	}
	.project::-webkit-scrollbar {
		display: none;
	}
	.projectTitle{
		text-decoration-line: underline;
		text-decoration-style: double;
		width: auto;
		justify-content: space-around;
		align-items: center;
		align-self: center;
		background-color: rgba(39, 10, 205, 0.5);
	}
	.portfolio-title{
		align-self: center;
		justify-self: center;
	}
	.photo{
		height: 150px;
		width: 40vw;
		border-radius: 10%;
	}
	.box{
		display: flex;
		flex-direction: column;
		text-decoration: none;
		align-items: center;
	}
	p.info{
		justify-self: center;
		align-self: center;
		background-color: rgba(39, 10, 205, 0.5);
		color: #F8F8F8;
	}
	p.indent{
		text-indent: 50px;
	}
	a, a:visited{
		font-family: 'Libre Baskerville', serif;
		color: #F8F8F8;
		font-weight: bolder;
		font-size: large;
	}

	.content{
		display: flex;
		flex-direction: column;
		padding-right: 10px;
		padding-left: 10px;
		width: 100%;
		background-color: rgba(39, 10, 205, 0.5);
	}
	li{
		background: none;
	}
}
@media only screen and (min-width: 601px){ /* Windows/Mac */
	* { 
		margin:0;
		padding:0;
		box-sizing: border-box;
	}
	.projects{
		display: flex;
		flex-direction: column;
		height: 80vh;
		padding: 10px;
		font-family: 'Libre Baskerville', serif;
		color: #F8F8F8;
		font-weight: bolder;
		text-shadow: 1px 2px 4px black;
		font-size: 1.25em;
	}
	.projectList{
		display: flex;
		height: 90vh;
		flex-direction: column;
		justify-content: space-around;
		overflow: scroll;
	}
	.projectList::-webkit-scrollbar {
		display: none;
	  }
	
	.project{
		align-content: flex-start;
		border-bottom: 1px solid #F8F8F8;
		padding-top: 10px;
		padding-bottom: 10px;
		display: flex;
		flex-direction: row;
		overflow: scroll;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	.project::-webkit-scrollbar {
		display: none;
	}
	.projectTitle{
		text-decoration-line: underline;
		text-decoration-style: double;
		width: 10vw;
		background-color: rgba(0, 0, 0, 0.611);
	}
	.portfolio-title{
		align-self: center;
	}
	.description{
		margin-left: 15px;
	}
	.photo{
		height: 150px;
		width: 15vw;
		border-radius: 10%;
	}
	.box{
		display: flex;
		flex-direction: column;
		text-decoration: none;
	}
	p.info{
		justify-self: center;
		align-self: center;
	}
	a:visited{
		font-family: 'Libre Baskerville', serif;
		color: #F8F8F8;
		text-shadow: 1px 2px 4px black;
		font-weight: bolder;
		font-size: x-large;
	}
	.content{
		display: flex;
		flex-direction: column;
		padding-right: 10px;
		padding-left: 10px;
		width: 70vw;
		overflow: scroll;
		background-color: rgba(0, 0, 0, 0.7);
	}
	.content::-webkit-scrollbar {
		display: none;
	  }
}


