@media only screen and (max-width: 600px){ /* Mobile */
* { 
		margin:0;
		padding:0;
		box-sizing: border-box;
	} 
	.footer{
		display: flex;
		justify-content: space-around;
		justify-self: center;
		width: 50%;
		height: fit-content;
		position:sticky;
	}
	a:visited {
		color: navy;
	}
	svg{
		color: navy;
		filter: drop-shadow(3px 4px 2px  #F8F8F8);
	}
}
@media only screen and (min-width: 601px){ /* Windows/Mac */
	* { 
		margin:0;
		padding:0;
		box-sizing: border-box;
	} 
	.footer{
		display: flex;
		justify-content: space-around;
		justify-self: center;
		height: 10vh;
		width: 50%;
		position:sticky;
	}
	
	a:visited {
		color: navy;
	  }
	  svg{
		  color: #F8F8F8;
		  filter: drop-shadow(3px 4px 2px black);
	  }
}

